@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --main-bg-color: #39644c;
}

html {
    scroll-behavior: smooth;
}

body {
    padding: 0;
    margin: 0;
}

.test {
    /* background: #A1FFCE; */
    /* fallback for old browsers */
    /* background: -webkit-linear-gradient(to right, #FAFFD1, #A1FFCE); */
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to right, #FAFFD1, #A1FFCE); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-image: linear-gradient(180deg, #eaeacb, #fff 40%);
}

@layer components {

    .gradient {
        /* background: linear-gradient(0deg, rgba(57, 100, 76, 1) 0%, rgba(255, 255, 255, 1) 100%); */
        /* background-color: #b2e8c4; */
    }

    .flex-initial {
        flex-direction: initial;
    }

    .border-top {
        border-top: 1px solid #dbdbe0;
    }

    .justify-evenly {
        justify-content: space-evenly;
    }

    /* ... */
}


.splide__arrow--prev {
    left: 2rem !important;
}

.splide__arrow--next {
    right: 2rem !important;
}

.faq ul {
    list-style: none;
    perspective: 900;
    padding: 0;
    margin: 0;
}

.faq ul li {
    position: relative;
    padding: 0;
    margin: 0;
    padding-bottom: 4px;
    padding-top: 18px;
    border-top: 1px dotted #dce7eb;
}

.faq ul li:nth-of-type(1) {
    animation-delay: 0.5s;
}

.faq ul li:nth-of-type(2) {
    animation-delay: 0.75s;
}

.faq ul li:nth-of-type(3) {
    animation-delay: 1s;
}

.faq ul li:last-of-type {
    padding-bottom: 0;
}

.faq ul li i {
    position: absolute;
    transform: translate(-6px, 0);
    margin-top: 16px;
    right: 0;
}

.faq ul li i:before,
.faq ul li i:after {
    content: "";
    position: absolute;
    background-color: #39644c;
    width: 3px;
    height: 9px;
}

.faq ul li i:before {
    transform: translate(-2px, 0) rotate(45deg);
}

.faq ul li i:after {
    transform: translate(2px, 0) rotate(-45deg);
}

.faq ul li input[type=checkbox] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
}

.faq ul li input[type=checkbox]:checked~p {
    margin-top: 0;
    height: 0;
    transform: translate(0, 50%);
    display: none;
}

.faq ul li input[type=checkbox]:checked~div {
    margin-top: 0;
    max-height: 0;
    display: none;
    transform: translate(0, 50%);
}

.faq ul li input[type=checkbox]~i {
    /* transform: translate(0, 50%) rotate(180deg); */
}

.faq ul li input[type=checkbox]:checked~i {
    transform: translate(0, 50%) rotate(0deg);
}

.faq ul li input[type=checkbox]:checked~i:before {
    transform: translate(2px, 0) rotate(45deg);
}

.faq ul li input[type=checkbox]:checked~i:after {
    transform: translate(-2px, 0) rotate(-45deg);
}

@keyframes flipdown {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }

    5% {
        opacity: 1;
    }

    80% {
        transform: rotateX(8deg);
    }

    83% {
        transform: rotateX(6deg);
    }

    92% {
        transform: rotateX(-3deg);
    }

    100% {
        transform-origin: top center;
        transform: rotateX(0deg);
    }
}

.splide__pagination__page {
    margin-bottom: 2rem !important;
}

.splide__pagination__page.is-active {
    background: black !important;
}

#cardsReviewContainer {
    margin: 0 1rem !important;
}

.popup .close {
    position: absolute;
    top: 0;
    right: 1rem;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: white;
}

.popup .close:hover {
    color: #333;
}

.popup:target {
    display: none;
}

@media only screen and (max-width : 1024px) {

    /* Styles */
    .splide__arrow {
        width: 3em !important;
        height: 3em !important;
        background: #fdf7e6 !important;
        top: 94% !important;
    }

    #cardsReviewContainer {
        margin: 0 !important;
    }

    .popup .close {
        position: absolute;
        top: -5px;
        right: 1rem;
    }
}

.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    transform: none !important;
}

