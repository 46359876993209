*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.m-\[0\] {
  margin: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-\[2rem\] {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-\[10\%\] {
  margin-right: 10%;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-\[auto\] {
  height: auto;
}

.h-6 {
  height: 1.5rem;
}

.h-\[250px\] {
  height: 250px;
}

.h-auto {
  height: auto;
}

.h-\[300px\] {
  height: 300px;
}

.h-32 {
  height: 8rem;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[450px\] {
  height: 450px;
}

.h-full {
  height: 100%;
}

.h-\[176px\] {
  height: 176px;
}

.h-\[352px\] {
  height: 352px;
}

.h-\[18px\] {
  height: 18px;
}

.h-10 {
  height: 2.5rem;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-\[95\%\] {
  width: 95%;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[85\%\] {
  width: 85%;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[18px\] {
  width: 18px;
}

.w-10 {
  width: 2.5rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-\[440px\] {
  max-width: 440px;
}

.max-w-\[448px\] {
  max-width: 448px;
}

.max-w-md {
  max-width: 28rem;
}

.cursor-pointer {
  cursor: pointer;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  appearance: none;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-\[2rem\] {
  gap: 2rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-\[3rem\] {
  gap: 3rem;
}

.gap-\[0\.5rem\] {
  gap: .5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.rounded-\[32px\] {
  border-radius: 32px;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[64px\] {
  border-radius: 64px;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.bg-\[\#39644c\] {
  --tw-bg-opacity: 1;
  background-color: rgb(57 100 76 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[\#fdf7e6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 247 230 / var(--tw-bg-opacity));
}

.bg-\[\#f8f8f8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-\[\#d7ede1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(215 237 225 / var(--tw-bg-opacity));
}

.object-cover {
  object-fit: cover;
}

.p-\[0\] {
  padding: 0;
}

.p-\[1rem\] {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-\[1rem\] {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-\[3rem\] {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-\[2rem\] {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-\[3rem\] {
  padding-bottom: 3rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-\[4rem\] {
  padding-bottom: 4rem;
}

.pb-\[1\.8rem\] {
  padding-bottom: 1.8rem;
}

.pr-4 {
  padding-right: 1rem;
}

.text-center {
  text-align: center;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.italic {
  font-style: italic;
}

.leading-normal {
  line-height: 1.5;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-normal {
  letter-spacing: 0;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-textColorPrincipal {
  --tw-text-opacity: 1;
  color: rgb(57 100 76 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-\[\#39644c\] {
  --tw-text-opacity: 1;
  color: rgb(57 100 76 / var(--tw-text-opacity));
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.text-amber-300 {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-\[gold\] {
  --tw-text-opacity: 1;
  color: rgb(255 215 0 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.no-underline {
  text-decoration-line: none;
}

.shadow-\[0px_8px_20px_rgba\(0\,_0\,_0\,_0\.08\)\] {
  --tw-shadow: 0px 8px 20px #00000014;
  --tw-shadow-colored: 0px 8px 20px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

:root {
  --main-bg-color: #39644c;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

.test {
  background-image: linear-gradient(#eaeacb, #fff 40%);
}

.splide__arrow--prev {
  left: 2rem !important;
}

.splide__arrow--next {
  right: 2rem !important;
}

.faq ul {
  perspective: 900px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.faq ul li {
  border-top: 1px dotted #dce7eb;
  margin: 0;
  padding: 18px 0 4px;
  position: relative;
}

.faq ul li:nth-of-type(1) {
  animation-delay: .5s;
}

.faq ul li:nth-of-type(2) {
  animation-delay: .75s;
}

.faq ul li:nth-of-type(3) {
  animation-delay: 1s;
}

.faq ul li:last-of-type {
  padding-bottom: 0;
}

.faq ul li i {
  margin-top: 16px;
  position: absolute;
  right: 0;
  transform: translate(-6px);
}

.faq ul li i:before, .faq ul li i:after {
  content: "";
  width: 3px;
  height: 9px;
  background-color: #39644c;
  position: absolute;
}

.faq ul li i:before {
  transform: translate(-2px)rotate(45deg);
}

.faq ul li i:after {
  transform: translate(2px)rotate(-45deg);
}

.faq ul li input[type="checkbox"] {
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  position: absolute;
}

.faq ul li input[type="checkbox"]:checked ~ p {
  height: 0;
  margin-top: 0;
  display: none;
  transform: translate(0, 50%);
}

.faq ul li input[type="checkbox"]:checked ~ div {
  max-height: 0;
  margin-top: 0;
  display: none;
  transform: translate(0, 50%);
}

.faq ul li input[type="checkbox"]:checked ~ i {
  transform: translate(0, 50%)rotate(0);
}

.faq ul li input[type="checkbox"]:checked ~ i:before {
  transform: translate(2px)rotate(45deg);
}

.faq ul li input[type="checkbox"]:checked ~ i:after {
  transform: translate(-2px)rotate(-45deg);
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top;
    transform: rotateX(-90deg);
  }

  5% {
    opacity: 1;
  }

  80% {
    transform: rotateX(8deg);
  }

  83% {
    transform: rotateX(6deg);
  }

  92% {
    transform: rotateX(-3deg);
  }

  100% {
    transform-origin: top;
    transform: rotateX(0);
  }
}

.splide__pagination__page {
  margin-bottom: 2rem !important;
}

.splide__pagination__page.is-active {
  background: #000 !important;
}

#cardsReviewContainer {
  margin: 0 1rem !important;
}

.popup .close {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  transition: all .2s;
  position: absolute;
  top: 0;
  right: 1rem;
}

.popup .close:hover {
  color: #333;
}

.popup:target {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .splide__arrow {
    width: 3em !important;
    height: 3em !important;
    background: #fdf7e6 !important;
    top: 94% !important;
  }

  #cardsReviewContainer {
    margin: 0 !important;
  }

  .popup .close {
    position: absolute;
    top: -5px;
    right: 1rem;
  }
}

.sticky-header {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: none !important;
}

.hover\:bg-\[\#2c4e3a\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(44 78 58 / var(--tw-bg-opacity));
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.focus\:border-\[\#39644c\]:focus {
  --tw-border-opacity: 1;
  border-color: rgb(57 100 76 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

@media (min-width: 640px) {
  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:pb-24 {
    padding-bottom: 6rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .md\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .md\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .md\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .md\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .md\:container {
      max-width: 1536px;
    }
  }

  .md\:left-2 {
    left: .5rem;
  }

  .md\:bottom-1\/2 {
    bottom: 50%;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:translate-y-1\/2 {
    --tw-translate-y: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:rounded-xl {
    border-radius: .75rem;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:p-\[1\.5rem\] {
    padding: 1.5rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1024px) {
  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mt-\[3rem\] {
    margin-top: 3rem;
  }

  .lg\:h-\[520px\] {
    height: 520px;
  }

  .lg\:h-\[620px\] {
    height: 620px;
  }

  .lg\:w-\[1151px\] {
    width: 1151px;
  }

  .lg\:w-\[450px\] {
    width: 450px;
  }

  .lg\:w-\[550px\] {
    width: 550px;
  }

  .lg\:w-\[50\%\], .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-\[448px\] {
    width: 448px;
  }

  .lg\:max-w-\[440px\] {
    max-width: 440px;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-around {
    justify-content: space-around;
  }

  .lg\:object-\[right_-150px\] {
    object-position: right -150px;
  }

  .lg\:object-\[right_-320px\] {
    object-position: right -320px;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:text-left {
    text-align: left;
  }
}

/*# sourceMappingURL=index.e5cae6c9.css.map */
